// @ts-nocheck
import React from "react";
import {
  useFlexLayout,
  useResizeColumns,
  useTable,
  useSortBy,
  useFilters,
  useGlobalFilter,
} from "react-table";
import { Box, Flex, PseudoBox, Text, Divider, Input } from "@chakra-ui/core";

import { WrapperHeader } from "./BaseWrapper";
const headerProps = (props, { column }) => getStyles(props, column.align);

const cellProps = (props, { cell }) => getStyles(props, cell.column.align);

const getStyles = (props, align = "left") => [
  props,
  {
    style: {
      justifyContent: align === "right" ? "flex-end" : "flex-start",
      alignItems: "flex-start",
      display: "flex",
    },
  },
];

const resizerProps = {
  position: "absolute",
  top: 0,
  right: 0,
  width: "10px",
  height: "100%",
  zIndex: 1,
  style: { touchAction: "none" },
};

function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const submit = () => {
    setGlobalFilter(value || undefined);
  };
  return (
    <WrapperHeader searchSubmit={submit}>
      <Input
        h="100%"
        border="0px"
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          //onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
      />
    </WrapperHeader>
  );
}

const Table = ({
  columns,
  data,
  emptyMessage = "No records",
  onRowClick,
  children,
  ...rest
}: {
  columns: Array;
  data: Object;
  emptyMessage?: String;
  onRowClick?: Function;
  children?: any;
}) => {
  const filterTypes = React.useMemo(
    () => ({
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // When using the useFlexLayout:
      minWidth: 30, // minWidth is only used as a limit for resizing
      width: 150, // width is used for both the flex-basis and flex-grow
      maxWidth: 300, // maxWidth is only used as a limit for resizing
    }),
    []
  );

  const {
    getTableProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
    },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
    useResizeColumns,
    useFlexLayout,
    (hooks) => {
      hooks.allColumns.push((columns) => columns);
    },
    useSortBy
  );

  return (
    <>
      <Box {...getTableProps()} flex={1} fontSize="sm" {...rest}>
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={state.globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
        <Box p={4}>
          <Box
            overflowY="auto"
            overflowX="hidden"
            borderBottom="1px solid"
            borderColor="gray.300"
          >
            {headerGroups.map((headerGroup, i) => (
              <Flex flex={1} key={i} {...headerGroup.getHeaderGroupProps({})}>
                {headerGroup.headers.map((column, i, cols) => (
                  <Text
                    h="40px"
                    as="div"
                    key={i}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    textAlign="left"
                    minHeight="24px"
                    fontWeight="bold"
                    color="blackPrimary"
                  >
                    {column.render("Header")}

                    {column.canResize && i < cols.length - 1 && (
                      <Box {...resizerProps} {...column.getResizerProps()} />
                    )}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " 🔽"
                          : " 🔼"
                        : ""}
                    </span>
                  </Text>
                ))}
              </Flex>
            ))}
          </Box>
          <Box overflowY="scroll" overflowX="hidden">
            {rows.length ? (
              rows.map((row) => {
                prepareRow(row);
                return (
                  <>
                    <PseudoBox
                      display="flex"
                      flex={1}
                      key={row.id}
                      data-rowindex={row.index}
                      {...row.getRowProps()}
                      onClick={onRowClick}
                      _hover={{ bg: "gray.100" }}
                      minHeight="24px"
                      lineHeight="24px"
                    >
                      {row.cells.map((cell) => {
                        return (
                          <Text
                            as="div"
                            key={row.id + "_" + cell.index}
                            wordBreak="break-all"
                            {...cell.getCellProps(cellProps)}
                          >
                            {cell.render("Cell")}
                          </Text>
                        );
                      })}
                    </PseudoBox>
                    <Divider />
                  </>
                );
              })
            ) : (
              <Text as="div" p="4px 24px" fontSize="md">
                {emptyMessage}
              </Text>
            )}
          </Box>
        </Box>

        {children}
      </Box>
    </>
  );
};

export default Table;
