import {
  Box,
  Flex,
  Icon,
  IconButton,
  IconButtonProps,
  Image,
  FlexProps,
} from "@chakra-ui/core";
import React from "react";
import { navigate } from "gatsby";
import { CoreLogo } from "./CoreLogo";
import SEO from "./SEO";
const menuWidth = "64px";

interface WrapperHeaderProps extends FlexProps {
  searchSubmit?: () => void;
}

export const WrapperHeader: React.FC<WrapperHeaderProps> = ({
  searchSubmit,
  children,
  ...rest
}) => {
  return (
    <Flex
      direction="row"
      h={menuWidth}
      w="100%"
      zIndex={1}
      flexShrink={0}
      borderBottom="1px"
      borderBottomColor="gray.200"
      {...rest}
    >
      <MenuIconButton
        aria-label="Search"
        icon="search"
        borderRight="1px"
        borderRightColor="gray.200"
        onClick={() => searchSubmit && searchSubmit()}
      />
      {children}
    </Flex>
  );
};
export const WrapperFooter: React.FC = () => {
  return (
    <Flex
      direction="row"
      h={menuWidth}
      w="100%"
      zIndex={1}
      flexShrink={0}
      borderTop="1px"
      borderTopColor="gray.200"
    ></Flex>
  );
};
export const MenuIconButton: React.FC<IconButtonProps> = (props) => {
  return (
    <IconButton
      {...props}
      h={menuWidth}
      w={menuWidth}
      variant="ghost"
      //variantColor="gray.300"
      fontSize="20px"
    />
  );
};
export const BaseWrapper: React.FC = ({ children }) => {
  const menuWidth = "64px";

  const pathname =
    typeof window !== "undefined" ? window.location.pathname : "";

  const WrapperLeftSideBar: React.FC = () => {
    return (
      <Flex
        direction="column"
        w={menuWidth}
        h="vh"
        zIndex={1}
        flexShrink={0}
        borderRight="1px"
        borderRightColor="gray.200"
        justify="space-between"
      >
        <Box
          cursor="pointer"
          onClick={() => navigate("/")}
          borderBottom="1px"
          borderBottomColor="gray.200"
        >
          <CoreLogo />
        </Box>

        <Flex
          size={menuWidth}
          borderTop="1px"
          borderTopColor="gray.200"
          justify="center"
          align="center"
          cursor="pointer"
          onClick={() =>
            navigate(pathname === "/table" ? "/network" : "/table")
          }
        >
          <Icon
            name={pathname === "/table" ? "mapIcon" : "menuIcon"}
            aria-label="menu"
            color="gray.300"
            size="24px"
          />
        </Flex>
      </Flex>
    );
  };
  const WrapperRightSideBar: React.FC = () => {
    return (
      <Flex
        direction="column"
        w={menuWidth}
        h="100vh"
        zIndex={1}
        flexShrink={0}
        justify="space-between"
        borderLeft="1px"
        borderLeftColor="gray.200"
      >
        <Box>
          <MenuIconButton
            aria-label="Close"
            icon="close"
            borderBottom="1px"
            borderBottomColor="gray.200"
          />
          <MenuIconButton
            aria-label="Favorite"
            icon="star"
            borderBottom="1px"
            borderBottomColor="gray.200"
          />
        </Box>

        <Box>
          <MenuIconButton
            aria-label="Settings"
            icon="settings"
            borderTop="1px"
            borderTopColor="gray.200"
          />
        </Box>
      </Flex>
    );
  };
  return (
    <Flex direction="row" h="100vh" overflowX="hidden">
      <SEO title="Innovation Map" />
      <WrapperLeftSideBar />
      <Flex direction="column" width="100%" h="100vh" overflowX="hidden">
        {/* <WrapperHeader /> */}
        <Flex overflowY="scroll" overflowX="hidden">
          {children}
        </Flex>

        {/* <WrapperFooter /> */}
      </Flex>
      {/* <WrapperRightSideBar /> */}
    </Flex>
  );
};
