import React, { useState, useEffect } from "react";
import Table from "./Table";
import { Flex, Stack, Text, Link, Image, Collapse, Box } from "@chakra-ui/core";
import { ICompany } from "../types";
import useWindowDimensions from "../hooks/useWindowDimensions";

interface CompaniesTableProps {
  companies: ICompany[];
  companyClicked: (id: string) => void;
  selectedCompany: string;
}

export const CompaniesTable: React.FC<CompaniesTableProps> = ({
  companies,
  companyClicked,
  selectedCompany,
}) => {
  const { width } = useWindowDimensions();
  const [columnsDisplayed, setColumnsDisplayed] = useState<any[]>([]);

  const columns = [
    {
      Header: "Name",
      accessor: "name",
      sortType: "basic",

      Cell: ({ cell: { value } }: { cell: { value: any } }) => {
        const [show, setShow] = useState(false);

        const handleToggle = () => setShow(!show);
        return (
          <Flex
            minH="155px"
            paddingTop="16px"
            maxW={["270px", "100%"]}
            minW="240px"
          >
            <Box
              h={["35px", "55px"]}
              w={["35px", "55px"]}
              mr={["15px", "15px"]}
              mt={[0, "32px"]}
            >
              {value[2] && <Image src={value[2]} />}
            </Box>

            <Stack maxW={["171px", "270px"]}>
              <Text fontSize={["14px", "16px"]} color="blackPrimary">
                {value[0]}
                <br />
              </Text>
              <Collapse
                startingHeight={20}
                isOpen={show}
                fontSize={["12px", "14px"]}
              >
                {value[3]}
              </Collapse>
              <Link
                onClick={handleToggle}
                color="blackPrimary"
                fontSize={["12px", "14px"]}
              >
                Read {show ? "Less" : "More"}
              </Link>

              <Text
                color="blackPrimary"
                fontWeight="bold"
                fontSize={["12px", "14px"]}
              >
                Website:{" "}
                <Link href={"https://" + value[1]} isExternal>
                  {value[1]}
                </Link>
              </Text>
            </Stack>
          </Flex>
        );
      },
    },
  ];

  const displayFullScreen = [
    {
      Header: "Primary Commodity",
      accessor: "primary_commodity",
      sortType: "basic",
      Cell: ({ cell: { value } }: { cell: { value: any } }) => {
        return (
          <Flex minH="155px" justify="center" align="center">
            <Text fontSize="14px" color="blackPrimary">
              {value}
            </Text>
          </Flex>
        );
      },
    },

    {
      Header: "Industry Sector",
      accessor: "industry_sector",
      sortType: "basic",
      Cell: ({ cell: { value } }: { cell: { value: any } }) => {
        return (
          <Flex minH="155px" justify="center" align="center">
            <Text fontSize="14px" color="blackPrimary">
              {value}
            </Text>
          </Flex>
        );
      },
    },
  ];
  const displayMediumWidthScreenColumns = [
    {
      Header: "No. Of Employees",
      accessor: "number_of_employees",
      sortType: "basic",
      Cell: ({ cell: { value } }: { cell: { value: any } }) => {
        return (
          <Flex minH="155px" justify="center" align="center">
            <Text fontSize="14px" color="blackPrimary">
              {value}
              {" Employees"}
            </Text>
          </Flex>
        );
      },
    },
    {
      Header: "City",
      accessor: "city",
      sortType: "basic",
      Cell: ({ cell: { value } }: { cell: { value: any } }) => {
        return (
          <Flex minH="155px" justify="center" align="center">
            <Text fontSize="14px" color="blackPrimary">
              {value}
            </Text>
          </Flex>
        );
      },
    },
  ];
  useEffect(() => {
    if (width < 375 && width < 800) {
      setColumnsDisplayed(columns);
    } else if (width < 800) {
      setColumnsDisplayed([...columns, ...displayMediumWidthScreenColumns]);
    } else {
      setColumnsDisplayed([
        ...columns,
        ...displayFullScreen,
        ...displayMediumWidthScreenColumns,
      ]);
    }
  }, [width]);

  const [filterValue, setFilterValue] = useState<string>();

  //add values here to inject them into the first column cell
  const data = companies.map((company) => {
    return {
      name: [
        company.properties.name,
        company.properties.domain,
        company.properties.logo_url,
        company.properties.description,
      ],
      primary_commodity: company.properties.primary_commodity,
      industry_sector: company.properties.industry,
      city: company.properties.city,
      number_of_employees: company.properties.numberofemployees,
    };
  });
  const handleRowClick = (e: { target: any }) => {
    // console.log(e);
    let el = e.target;
    while (!Object.prototype.hasOwnProperty.call(el.dataset, "rowindex"))
      el = el.parentNode;

    const company = companies[el.dataset.rowindex];
    companyClicked(company.id);
  };

  return (
    <Stack spacing="0px">
      <Flex
        flex={1}
        // p={4}
        background="#FAFAFA"
        // borderY="1px"
        // borderColor="gray.200"
      >
        <Table
          columns={columnsDisplayed}
          data={data}
          onRowClick={handleRowClick}
        />
      </Flex>
    </Stack>
  );
};
