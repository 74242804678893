import React, { useEffect, useRef, useState } from "react";
// import { fb } from "../utils/firebase";
import { createCtx } from "./createCtx";
import firebase from "gatsby-plugin-firebase";

import { ICompany, IHubspotCompanyProperties } from "../types";

interface CompaniesContextProps {
  companies: ICompany[];
  loading: boolean;
  error: boolean;
}

const [useCompanies, CompaniesContextProvider] = createCtx<
  CompaniesContextProps
>();

const CompaniesProvider: React.FC = ({ children }) => {
  const [companies, setCompanies] = useState<ICompany[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  useEffect(() => {
    getCompanies();
  }, []);

  const getCompanies = async () => {
    setLoading(true);
    const querySnap = await firebase.firestore().collection("elements").get();
    const companiesData = querySnap.docs.map((doc) => {
      return {
        id: doc.id,
        properties: doc.data() as IHubspotCompanyProperties,
      };
    });
    setCompanies(companiesData);
    setLoading(false);
  };

  return (
    <CompaniesContextProvider
      value={{
        companies,
        loading,
        error,
      }}
    >
      {children}
    </CompaniesContextProvider>
  );
};

export { CompaniesProvider, useCompanies };
