import React, { useState } from "react";

import { Box } from "@chakra-ui/core";
import { BaseWrapper } from "../components/BaseWrapper";
import { useCompanies, CompaniesProvider } from "../context/CompaniesContext";
import { CompaniesTable } from "../components/CompaniesTable";

const InnovationTable: React.FC = () => {
  return (
    <CompaniesProvider>
      <BaseWrapper>
        <Box
          margin="0"
          padding="0"
          overflowY="scroll"
          overflowX="hidden"
          width="100%"
          h="100%"
        >
          <TableElements />
        </Box>
      </BaseWrapper>
    </CompaniesProvider>
  );
};

export const TableElements: React.FC = () => {
  const { companies } = useCompanies();
  const [selectedNode, setSelectedNode] = useState<string>("");

  return (
    <CompaniesTable
      companies={companies}
      companyClicked={setSelectedNode}
      selectedCompany={selectedNode}
    />
  );
};

export default InnovationTable;
